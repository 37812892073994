import React from 'react';
import Image from './image';
import Navbar from './navbar';

const MainHeader = () => (
    <header className='hero is-link is-fullheight' style={{ position: `relative` }}>
      <Navbar/>
      <Image/>
      <div className='hero-body'>
        <div className='container'>
          <p>1-2 czerwca 2019, Oleśnica</p>
          <h1 className='title is-1'>III Turniej tańca nowoczesnego</h1>
          <h1 className='title is-1'>"TOP"</h1>
          <p>
            <a href='https://www.facebook.com/pg/TurniejTancaTop/photos/?tab=album&album_id=2401421493254750'
                target='_blank'
                style={{marginBottom: '10px'}}
                className='button is-danger is-large'>Fotorelacja 2019 cz. 1</a>
          </p>
          <p>
            <a href='https://www.facebook.com/TurniejTancaTop/photos/?tab=album&album_id=2407235809339985'
                target='_blank'
                className='button is-large'>Fotorelacja 2019 cz. 2</a>
          </p>
        </div>
      </div>
      <div className='hero-foot has-text-centered is-size-4' style={{zIndex: `1`}}>
        <i className="fas fa-chevron-down"></i>
      </div>
    </header>
);

export default MainHeader;