import React, { Component } from 'react';

export default class Navbar extends Component {

  render() {
    return (
        <nav className="navbar is-transparent">
          <div className="container">
            <div className="navbar-brand">
              <div className="navbar-burger burger">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            <div className="navbar-menu">
              <div className="navbar-end">
                <a className="navbar-item" href="#szczegoly">
                  Szczegóły
                </a>
                <a className="navbar-item" href="#jury">
                  Jury
                </a>
                <a className="navbar-item" href="#regulamin">
                  Regulamin 2019
                </a>
                <a className="navbar-item" href="#lokalizacja">
                  Lokalizacja
                </a>
                <a className="navbar-item" href="#turnieje">
                  Nasze turnieje
                </a>
                <a className="navbar-item" href="#kontakt">
                  Kontakt
                </a>
              </div>
            </div>
          </div>
        </nav>
    )
  }
}

