import React from "react";
import MainHeader from '../components/main-header';
import trendy from '../images/trendy-logo.jpg';
import brylka from '../images/brylka.jpg';
import wlodek from '../images/wlodek.png';
import mirek from '../images/mirek.jpg';
import mostek from '../images/mostek.jpg';
import przemek from '../images/przemek.jpg';
import SEO from '../components/seo';

const IndexPage = () => (
    <>
      <SEO title='Strona główna'/>
      <MainHeader/>

      <section className="section has-background-white" id="szczegoly">
        <div className="container">
          <h2 className="subtitle">
            Zapraszamy najlepszych tancerzy na Dolny Śląsk :)
          </h2>
        </div>
      </section>

      <section className="has-background-white section">
        <div className="container">
          <h1 className="title">Kategorie taneczne</h1>
          <h2 className="subtitle">
            <div className="tags">
              <span className="tag is-large">Art (pierwszy kroczek)</span>
              <span className="tag is-large">Taniec nowoczesny (pierwszy kroczek)</span>
              <span className="tag is-large">Hip hop</span>
              <span className="tag is-large">Jazz</span>
              <span className="tag is-large">Taniec współczesny</span>
              <span className="tag is-large">Disco dance</span>
              <span className="tag is-large">Disco show</span>
              <span className="tag is-large">Show dance</span>
              <span className="tag is-large">Balet</span>
              <span className="tag is-large">Inne formy tańca</span>
            </div>
          </h2>
        </div>
      </section>

      <section className="has-background-white section">
        <div className="container">
          <h1 className="title">Grupy wiekowe</h1>
          <h2 className="subtitle">
            <div className="tags">
              <span className="tag is-large">do 8 lat</span>
              <span className="tag is-large">9-11 lat</span>
              <span className="tag is-large">12-15 lat</span>
              <span className="tag is-large">powyżej 15 lat</span>
              <span className="tag is-large">30+</span>
            </div>
          </h2>
        </div>
      </section>

      <section className="has-background-white section">
        <div className="container">
          <h1 className="title">Poziomy zaawansowania</h1>
          <h2 className="subtitle">
            <div className="tags">
              <span className="tag is-large">debiuty</span>
              <span className="tag is-large">mistrzowie</span>
            </div>
          </h2>
        </div>
      </section>

      <section className="section" id="jury">
        <div className="container">
          <h1 className="title">Jury</h1>

          <div className="columns">
            <div className="column is-one-quarter-widescreen is-one-third-desktop is-half-tablet">
              <figure className="image" style={{height: `256px`, width: `256px`}}>
                <img alt="Katarzyna Bryłka" className="is-rounded" src={ brylka }/>
                <h2 className="subtitle" style={{textAlign: `center`}}>Katarzyna Bryłka</h2>
              </figure>
            </div>
            <div className="column is-one-quarter-widescreen is-one-third-desktop is-half-tablet">
              <figure className="image" style={{height: `256px`, width: `256px`}}>
                <img alt="Włodek Kołobycz" className="is-rounded" src={ wlodek }/>
                <h2 className="subtitle" style={{textAlign: `center`}}>Włodek Kołobycz</h2>
              </figure>
            </div>
            <div className="column is-one-quarter-widescreen is-one-third-desktop is-half-tablet">
              <figure className="image" style={{height: `256px`, width: `256px`}}>
                <img alt="Marta Mościcka" className="is-rounded" src={ mostek }/>
                <h2 className="subtitle" style={{textAlign: `center`}}>Marta Mościcka</h2>
              </figure>
            </div>
            <div className="column is-one-quarter-widescreen is-one-third-desktop is-half-tablet">
              <figure className="image" style={{height: `256px`, width: `256px`}}>
                <img alt="Mirosław Nędzewicz" className="is-rounded" src={ mirek }/>
                <h2 className="subtitle" style={{textAlign: `center`}}>Mirosław Nędzewicz</h2>
              </figure>
            </div>
            <div className="column is-one-quarter-widescreen is-one-third-desktop is-half-tablet">
              <figure className="image" style={{height: `256px`, width: `256px`}}>
                <img alt="Przemysław Polakowski" className="is-rounded" src={ przemek }/>
                <h2 className="subtitle" style={{textAlign: `center`}}>Przemysław Polakowski</h2>
              </figure>
            </div>
          </div>
        </div>
        <p className='has-text-centered'>
          <a href='http://kartazgloszen.pl' target='_blank' className='button is-large'>Karta zgłoszenia</a>
        </p>
      </section>


      <section className="section has-background-white" id="regulamin">
        <div className="container">
          <h1 className="title">Regulamin</h1>
          <h2 className="subtitle">
            Pobierz regulamin najbliższej edycji Turnieju
          </h2>
          <p className='has-text-centered'>
            <a className="button is-large"
                href="http://top.setenta.wroclaw.pl/static/top-regulamin-2019.pdf"
                download>Pobierz regulamin 2019</a>
          </p>

          {/*<h2 className='subtitle'>*/}
          {/*  Zmiany w regulaminie*/}
          {/*</h2>*/}

          {/*<article className="message is-info">*/}
          {/*  <div className="message-header">*/}
          {/*    <p>13 marca 2019</p>*/}
          {/*  </div>*/}
          {/*  <div className="message-body">*/}
          {/*    <ul>*/}
          {/*      <li>- Doprecyzowano sposób obliczania przynależności do grup wiekowych</li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</article>*/}
        </div>
      </section>


      <section className="section" id="lokalizacja">
        <div className="container">
          <h1 className="title">Lokalizacja</h1>
          <h2 className="subtitle">
            Hala sportowa ATOL w Oleśnicy
            <br/>
            ul. Kochanowskiego 2, Oleśnica
          </h2>

          <p className='has-text-centered'>
            <a className="button is-large" rel="noopener noreferrer"
                href="https://goo.gl/maps/RiCbWzX1rY4EtY6h8" target="_blank">
              <i className="fas fa-map-marker-alt"></i>&nbsp;Zobacz na mapie
            </a>
          </p>
        </div>
      </section>


      <section className="section has-background-white" id="turnieje">
        <div className="container">
          <h1 className="title">Nasze turnieje</h1>
          <h2 className="subtitle">
            <a href='http://trendy.setenta.wroclaw.pl' target='_blank'>
              <figure className="image is-128x128">
                <img className="is-rounded" alt="Festiwal Tańca Trendy"
                    src={trendy}/>
              </figure>
              Festiwal tańca TRENDY
            </a>
          </h2>
        </div>
      </section>


      <section className="section" id="kontakt">
        <div className="container">
          <h1 className="title">Kontakt</h1>
          <h2 className="subtitle">
            Szkoła tańca Setenta
            <br/>
            tel. 695 081 437
            <br/>
            <a href='mailto:turniej@setenta.wroclaw.pl'>turniej@setenta.wroclaw.pl</a>
          </h2>
        </div>
      </section>
    </>
);
export default IndexPage